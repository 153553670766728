import React from "react";
import { Box, Text } from "@chakra-ui/react";

const cities = [
    "New York", "Los Angeles", "Chicago", "Houston", "Phoenix", "Philadelphia", "San Antonio", "San Diego", "Dallas", "San Jose",
    "Austin", "Jacksonville", "Fort Worth", "Columbus", "Charlotte", "San Francisco", "Indianapolis", "Seattle", "Denver", "Washington",
    "Boston", "El Paso", "Nashville", "Detroit", "Oklahoma City", "Portland", "Las Vegas", "Memphis", "Louisville", "Baltimore",
    "Milwaukee", "Albuquerque", "Tucson", "Fresno", "Sacramento", "Kansas City", "Mesa", "Atlanta", "Omaha", "Colorado Springs",
    "Raleigh", "Miami", "Long Beach", "Virginia Beach", "Oakland", "Minneapolis", "Tulsa", "Arlington", "New Orleans", "Wichita"
];

function getRandomElement(array) {
    return array[Math.floor(Math.random() * array.length)];
}

function getRandomTime(unit) {
    switch (unit) {
        case "seconds":
            return Math.floor(Math.random() * 60);
        case "minutes":
            return Math.floor(Math.random() * 60);
        case "hours":
            return Math.floor(Math.random() * 24);
        default:
            return 0;
    }
}

function LastSale({ timeUnit }) {
    const randomCity = getRandomElement(cities);
    const randomTime = getRandomTime(timeUnit);

    return (
        <Box
            p={4}
            bgGradient="linear(to-b, gray.200, gray.300)"
            borderRadius="lg"
            boxShadow="md"
            textAlign="center"
            maxW="md"
            mx="auto"
            mb={5}
        >
            <Text fontSize="lg" color="black" fontWeight="medium">
                Just purchased in
            </Text>
            <Text fontSize="lg" fontWeight="bold" color="black">
                {randomCity}, <Text as="span" color="gray.700">{randomTime} {timeUnit} ago.</Text>
            </Text>
        </Box>
    );
}

export default LastSale;
